<template>
  <a-modal title="已匹配的企业" style="width: 800px;" @ok="submit" :confirm-loading="loading" v-model:visible="flags.visible"
    @cancel="flags.visible = false;" @close="flags.visible = false;">
    <div class="modal-wrap">
      <div class="table">
        <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="list"
          :pagination="pagination" :loading="loading" @change="handleChange">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex == 'userinfo'">
              <div>
                <span>{{ record.userinfo?.phone || '--' }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'index'">
              <div>
                <span>{{ index + 1 }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'company_info'">
              <div>
                <span>{{ record.company_info?.qymc || '--' }}</span>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>
<script>
import service from '@/service/service';
// import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      list: [],
      form: {
        id: '',
      },
      flags: {
        visible: false,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      loading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
        },
        {
          title: '匹配时间',
          dataIndex: 'input_time',
        },
        {
          title: '操作人',
          dataIndex: 'operate_name',
        },
        {
          title: '账号',
          dataIndex: 'userinfo',
        },
        {
          title: '企业名称',
          dataIndex: 'company_info',
        },
        {
          title: '匹配度',
          dataIndex: 'num',
        }
      ],
    }
  },
  methods: {
    async init(id) {
      this.form.id = id;
      this.flags.visible = true;
      this.pagination = {
        total: 0,
        current: 1,
        pageSize: 10,
      };
      this.getData();
    },
    async getData() {
      this.loading = true;
      let params = {
        article_id: this.form.id,
        page:this.pagination.current
      };

      const res = await service.get_match_company_lists(params);
      this.pagination = { ...this.pagination, total: res.total_number }
      this.loading = false;
      this.list = res.data || [];
    },
    handleChange(e) {
      this.pagination = { ...e };
      this.getData();
    },
    async submit() {
      this.flags.visible = false;
    }
  }
}
</script>
<style lang="less" scoped>
.modal-wrap {}
</style>